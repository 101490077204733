import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { DepositWithdrawalHistoryResponse } from "@/shared/repository/modules/deposit-withdrawal/repo";
import { SvgAttribute } from "@/shared/constants/constants";
import { ApplicationStatus, RoutesName } from "@/shared/constants/enums";
import { formatFiatCurrency } from "@/shared/utils/amount-helpers";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    row: {
      type: <PropType<DepositWithdrawalHistoryResponse>>Object,
      required: true,
    },
    isDetails: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    depositWithdrawal: null,
    remove: null,
  },
  data() {
    return {
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
    };
  },
  computed: {
    displayedSvg(): SvgAttribute {
      return SVG;
    },

    displayedStatus(): string {
      switch (this.row.status) {
        case ApplicationStatus.pending:
          return "Создано";
        case ApplicationStatus.waiting:
          return "Ожидание подтверждения";
        case ApplicationStatus.inWork:
          return "В работе";
        case ApplicationStatus.reject:
          return "Отклонено";
        case ApplicationStatus.completed:
          return "Выполнено";
        default:
          return "Создано";
      }
    },

    displayedAmount(): string {
      return formatFiatCurrency(this.row.amount);
    },

    isConfirmShow(): boolean {
      return this.row.status === ApplicationStatus.pending;
    },

    isRemoveShow(): boolean {
      return this.row.status === ApplicationStatus.pending;
    },
  },
  methods: {
    handleConfirm(): void {
      this.$router.push({ name: RoutesName.wallet });

      this.depositWithdrawalRepo.UPDATE_DEPOSIT({
        id: this.row.id,
        currency_id: this.row.currency_id,
        amount: this.row.amount,
        currencyName: this.row.currency.name,
      });
    },

    handleRemove(): void {
      this.$emit("remove", this.row.id);
    },

    handleRow(): void {
      this.$emit("depositWithdrawal", this.row);
    },
  },
});
