import { AxiosInstance } from "axios";

import { DepositWithdrawalUrlGenerator } from "./url-generator";
import { AdminApplicationType } from "../admin/application/enums";
import { AdminCurrencyType } from "../admin/currency/enums";
import {
  DepositWithdrawalConfirmationRequest,
  DepositWithdrawalHistoryResponse,
  DepositWithdrawalShowResponse,
  DepositWithdrawalStoreRequest,
} from "./repo";
import {
  HistoryFilterRequest,
  ResponseData,
  ResponseDataData,
} from "@/shared/constants/interfaces";
import {
  DepositWithdrawalHistoryResponseFactory,
  DepositWithdrawalShowResponseFactory,
} from "./factory";
import { AdminRequisitesListResponseFactory } from "../admin/commission/factory";
import { AdminCommissionRequisitesListResponse } from "../admin/commission/repo";

export interface DepositWithdrawalProjectRepository {
  store(
    appType: AdminApplicationType,
    currencyType: AdminCurrencyType,
    payload: DepositWithdrawalStoreRequest
  ): Promise<DepositWithdrawalShowResponse>;
  requisite(currencyId: number): Promise<AdminCommissionRequisitesListResponse>;
  confirmation(payload: DepositWithdrawalConfirmationRequest): Promise<void>;
  show(id: number): Promise<DepositWithdrawalShowResponse>;
  delete(id: number): Promise<void>;
  history(
    payload: HistoryFilterRequest
  ): Promise<DepositWithdrawalHistoryResponse[]>;
}

export class DepositWithdrawalHttpRepo
  implements DepositWithdrawalProjectRepository
{
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: DepositWithdrawalUrlGenerator
  ) {}

  async store(
    appType: AdminApplicationType,
    currencyType: AdminCurrencyType,
    payload: DepositWithdrawalStoreRequest
  ): Promise<DepositWithdrawalShowResponse> {
    const { data } = await this.axios.post<
      ResponseData<DepositWithdrawalShowResponse>
    >(this.urlGenerator.store(appType, currencyType), payload);

    return DepositWithdrawalShowResponseFactory(data.data);
  }

  async requisite(
    currencyId: number
  ): Promise<AdminCommissionRequisitesListResponse> {
    const { data } = await this.axios.get<
      ResponseData<AdminCommissionRequisitesListResponse>
    >(this.urlGenerator.requisite(currencyId));

    return AdminRequisitesListResponseFactory(data.data);
  }

  async confirmation(
    payload: DepositWithdrawalConfirmationRequest
  ): Promise<void> {
    await this.axios.patch<void>(this.urlGenerator.confirmation(payload));
  }

  async show(id: number): Promise<DepositWithdrawalShowResponse> {
    const { data } = await this.axios.get<
      ResponseData<DepositWithdrawalShowResponse>
    >(this.urlGenerator.show(id));

    return DepositWithdrawalShowResponseFactory(data.data);
  }

  async delete(id: number): Promise<void> {
    await this.axios.delete<void>(this.urlGenerator.delete(id));
  }

  async history(
    payload: HistoryFilterRequest
  ): Promise<DepositWithdrawalHistoryResponse[]> {
    const { data } = await this.axios.get<
      | ResponseDataData<DepositWithdrawalHistoryResponse[]>
      | ResponseData<DepositWithdrawalHistoryResponse[]>
    >(this.urlGenerator.history(), {
      params: {
        "filter[date_from]": payload.date_from ? payload.date_from : undefined,
        "filter[date_to]": payload.date_to ? payload.date_to : undefined,
        "filter[amount_from]": payload.amount_from
          ? payload.amount_from
          : undefined,
        "filter[amount_to]": payload.amount_to ? payload.amount_to : undefined,
        "filter[status]": payload.status ? payload.status : undefined,
        "filter[currency_id]": payload.currency_id
          ? payload.currency_id
          : undefined,
        "filter[app_type]": payload.type ? payload.type : undefined,
        page: payload.page,
        per_page: payload.per_page,
      },
    });

    return "data" in data.data
      ? data.data.data.map((item) =>
          DepositWithdrawalHistoryResponseFactory(item)
        )
      : data.data.map((item) => DepositWithdrawalHistoryResponseFactory(item));
  }
}
