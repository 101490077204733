import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";
import { HistoryTableRow } from "./row";
import { HistoryTableRowApplication } from "./row-application";

import { UIButton } from "@/components/ui";
import { DepositWithdrawalHistoryResponse } from "@/shared/repository/modules/deposit-withdrawal/repo";
import { HistoryTab } from "@/shared/constants/enums";
import { ApplicationHistoryResponse } from "@/shared/repository/modules/application/repo";

type HistoryTableRowComponent =
  | typeof HistoryTableRow
  | typeof HistoryTableRowApplication;

export interface HistoryTableColumn {
  id: number;
  label: string;
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    HistoryTableRow,
    HistoryTableRowApplication,
    UIButton,
  },
  props: {
    history: {
      type: <
        PropType<
          DepositWithdrawalHistoryResponse[] | ApplicationHistoryResponse[]
        >
      >Array,
      default: () => [],
    },
  },
  emits: {
    depositWithdrawal: null,
    application: null,
    remove: null,
  },
  data() {
    return {
      historyRepo: this.$projectServices.historyRepo,
    };
  },
  computed: {
    displayedSearchValue(): string {
      return this.historyRepo.search.value;
    },

    displayedColumns(): HistoryTableColumn[] {
      switch (this.historyRepo.tab) {
        case HistoryTab.depositWithdrawal:
          return [
            "Дата и время",
            "Тип",
            "Сумма",
            "Валюта",
            "Комиссия",
            "Статус",
          ].map((column, columnIndex) => ({ id: columnIndex, label: column }));
        case HistoryTab.application:
          return [
            "Дата и время",
            "Операция",
            "Цена исполнения",
            "Объем сделки",
            "Комиссия",
            "№ сделки",
            "Статус",
            "Этап",
          ].map((column, columnIndex) => ({ id: columnIndex, label: column }));
        default:
          return [];
      }
    },

    displayedSmallColumns(): HistoryTableColumn[] {
      return [
        {
          id: 0,
          label: "Сумма",
        },
        {
          id: 1,
          label: "Валюта",
        },
        {
          id: 2,
          label: "Дата",
        },
        {
          id: 3,
          label: "Статус",
        },
      ];
    },

    displayedRow(): HistoryTableRowComponent {
      switch (this.historyRepo.tab) {
        case HistoryTab.depositWithdrawal:
          return HistoryTableRow;
        case HistoryTab.application:
          return HistoryTableRowApplication;
        default:
          return HistoryTableRow;
      }
    },

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    displayedRows():
      | DepositWithdrawalHistoryResponse[]
      | ApplicationHistoryResponse[] {
      switch (this.historyRepo.tab) {
        case HistoryTab.depositWithdrawal:
          return (this.history as DepositWithdrawalHistoryResponse[])
            .slice()
            .sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )
            .filter((item) =>
              `$${item.created_at} ${item.amount} ${item.currency.name}`
                .toLowerCase()
                .includes(this.displayedSearchValue.toLowerCase())
            ) as DepositWithdrawalHistoryResponse[];
        case HistoryTab.application:
          return (this.history as ApplicationHistoryResponse[])
            .slice()
            .sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )
            .filter((item) =>
              `$${item.created_at} ${item.amount} ${item.currency_from.name} ${item.currency_to.name}`
                .toLowerCase()
                .includes(this.displayedSearchValue.toLowerCase())
            ) as ApplicationHistoryResponse[];
        default:
          return [];
      }
    },

    isApplication(): boolean {
      return this.historyRepo.tab === HistoryTab.application;
    },
  },
  methods: {
    handleDepositWithdrawal(
      depositWithdrawal: DepositWithdrawalHistoryResponse
    ): void {
      this.$emit("depositWithdrawal", depositWithdrawal);
    },

    handleApplication(application: ApplicationHistoryResponse): void {
      this.$emit("application", application);
    },
    handleRemove(id: number): void {
      this.$emit("remove", id);
    },
  },
});
