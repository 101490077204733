<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('columns', { application: isApplication })">
      <div
        :class="b('column')"
        v-for="column in displayedColumns"
        :key="column.id"
      >
        <p :class="b('column-label')">
          {{ $t(column.label) }}
        </p>
      </div>

      <div
        :class="b('column', { small: true })"
        v-for="column in displayedSmallColumns"
        :key="column.id"
      >
        <p :class="b('column-label')">
          {{ $t(column.label) }}
        </p>
      </div>
    </div>

    <div :class="b('rows')">
      <component
        :class="b('row')"
        :is="displayedRow"
        v-for="row in displayedRows"
        :key="row.id"
        :row="row"
        @depositWithdrawal="handleDepositWithdrawal"
        @remove="handleRemove"
        @application="handleApplication"
      />
    </div>
  </div>
</template>
