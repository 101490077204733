import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { UIButton } from "@/components/ui";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
  },
  emits: {
    remove: null,
    close: null,
  },
  computed: {
    displayedCloseIcon(): string {
      return SVG.close;
    },

    displayedIcon(): string {
      return SVG.remove;
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("close");
    },

    handleRemove(): void {
      this.$emit("remove");
    },
  },
});
