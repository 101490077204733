export enum ApplicationApiRoutes {
  store = "/api/v1/exchange/app/store",
  show = "/api/v1/exchange/app/show",
  from = "/api/v1/exchange/app/from-account",
  to = "/api/v1/exchange/app/to-account",
  commission = "/api/v1/exchange/app/calculation-commission",
  verification = "/api/v1/exchange/app/verification",
  history = "/api/v1/exchange/app/history",
  delete = "/api/v1/exchange/app/delete",
}

export interface ApplicationProjectUrlGenerator {
  store(userId: number): string;
  show(id: number): string;
  from(currencyId: number, userId: number): string;
  to(currencyId: number, userId: number): string;
  commission(userId: number): string;
  verification(): string;
  history(): string;
}

export class ApplicationUrlGenerator implements ApplicationProjectUrlGenerator {
  store(userId: number): string {
    return `${ApplicationApiRoutes.store}?user_id=${userId}`;
  }

  show(id: number): string {
    return `${ApplicationApiRoutes.show}?id=${id}`;
  }

  from(currencyId: number, userId: number): string {
    return `${ApplicationApiRoutes.from}?currency_id=${currencyId}&user_id=${userId}`;
  }

  to(currencyId: number, userId: number): string {
    return `${ApplicationApiRoutes.to}?currency_id=${currencyId}&user_id=${userId}`;
  }

  commission(userId: number): string {
    return `${ApplicationApiRoutes.commission}?user_id=${userId}`;
  }

  verification(): string {
    return ApplicationApiRoutes.verification;
  }

  delete(id: number) {
    return `${ApplicationApiRoutes.delete}?id=${id}`;
  }

  history(): string {
    return ApplicationApiRoutes.history;
  }
}
