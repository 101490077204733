import { defineComponent } from "vue";
import i18n from "@/plugins/i18n";

import { COMPONENT_NAME, RegistrationPageComponent } from "./attributes";

import {
  RegistrationForm,
  RegistrationVerification,
  RegistrationMessage,
} from "@/components/pages/registration";
import { SharedAuthCard } from "@/components/shared";
import { UILink } from "@/components/ui";

import { Error } from "@/shared/constants/interfaces";
import { AuthRegistrationRequestFactory } from "@/shared/repository/modules/auth/factory";

type DisplayedComponent =
  | typeof RegistrationForm
  | typeof RegistrationVerification
  | typeof RegistrationMessage;

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    RegistrationForm,
    RegistrationVerification,
    RegistrationMessage,
    SharedAuthCard,
    UILink,
  },
  data() {
    return {
      component: RegistrationPageComponent.form,
      authRegistrationRequest: AuthRegistrationRequestFactory(),
      isLoading: false,
      message: "",
      authRepository: this.$projectServices.authRepository,
    };
  },
  computed: {
    displayedComponent(): DisplayedComponent {
      switch (this.component) {
        case RegistrationPageComponent.form:
          return RegistrationForm;
        case RegistrationPageComponent.verification:
          return RegistrationVerification;
        case RegistrationPageComponent.message:
          return RegistrationMessage;
        default:
          return RegistrationForm;
      }
    },

    isTerms(): boolean {
      return this.component === RegistrationPageComponent.verification;
    },
  },
  methods: {
    async handleRegistration(): Promise<void> {
      try {
        this.isLoading = true;
        this.message = "";

        await this.authRepository
          .registration({
            ...this.authRegistrationRequest,
            lang: i18n.global.locale,
          })
          .then(() => {
            this.component = RegistrationPageComponent.message;
          });
      } catch (e) {
        const { message } = <Error>e;

        this.message = message;
      } finally {
        this.isLoading = false;
      }
    },

    async handleVerification(): Promise<void> {},

    handleGoogle(): void {
      console.log("Google");
    },
  },
});
