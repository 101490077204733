import { AxiosInstance } from "axios";

import { ApplicationUrlGenerator } from "./url-generator";
import {
  ApplicationCommissionRequest,
  ApplicationCommissionResponse,
  ApplicationHistoryResponse,
  ApplicationShowResponse,
  ApplicationStoreRequest,
} from "./repo";
import {
  ApplicationCommissionResponseFactory,
  ApplicationHistoryResponseFactory,
  ApplicationShowResponseFactory,
} from "./factory";

import {
  HistoryFilterRequest,
  ResponseData,
} from "@/shared/constants/interfaces";

export interface ApplicationProjectRepository {
  store(payload: ApplicationStoreRequest, userId: number): Promise<void>;
  show(id: number): Promise<ApplicationShowResponse>;
  from(currencyId: number, userId: number): Promise<void>;
  to(currencyId: number, userId: number): Promise<void>;
  commission(
    payload: ApplicationCommissionRequest,
    abortController: AbortController,
    userId: number
  ): Promise<ApplicationCommissionResponse>;
  verification(code: string): Promise<void>;
  delete(id: number): Promise<void>;
  history(payload: HistoryFilterRequest): Promise<ApplicationHistoryResponse[]>;
}

export class ApplicationHttpRepo implements ApplicationProjectRepository {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: ApplicationUrlGenerator
  ) {}

  async store(payload: ApplicationStoreRequest, userId: number): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.store(userId), payload);
  }

  async show(id: number): Promise<ApplicationShowResponse> {
    const { data } = await this.axios.get<
      ResponseData<ApplicationShowResponse>
    >(this.urlGenerator.show(id));

    return ApplicationShowResponseFactory(data.data);
  }

  async from(currencyId: number, userId: number): Promise<void> {
    await this.axios.get<void>(this.urlGenerator.from(currencyId, userId));
  }

  async to(currencyId: number, userId: number): Promise<void> {
    await this.axios.get<void>(this.urlGenerator.to(currencyId, userId));
  }

  async delete(id: number): Promise<void> {
    await this.axios.delete<void>(this.urlGenerator.delete(id));
  }

  async commission(
    payload: ApplicationCommissionRequest,
    abortController: AbortController,
    userId: number
  ): Promise<ApplicationCommissionResponse> {
    const { data } = await this.axios.post<
      ResponseData<ApplicationCommissionResponse>
    >(this.urlGenerator.commission(userId), payload, {
      signal: abortController.signal,
    });

    return ApplicationCommissionResponseFactory(data.data);
  }

  async verification(code: string): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.verification(), {
      code,
    });
  }

  async history(
    payload: HistoryFilterRequest
  ): Promise<ApplicationHistoryResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<ApplicationHistoryResponse[]>
    >(this.urlGenerator.history(), {
      params: {
        "filter[date_from]": payload.date_from ? payload.date_from : undefined,
        "filter[date_to]": payload.date_to ? payload.date_to : undefined,
        "filter[amount_from]": payload.amount_from
          ? payload.amount_from
          : undefined,
        "filter[amount_to]": payload.amount_to ? payload.amount_to : undefined,
        "filter[status]": payload.status ? payload.status : undefined,
        "filter[currency_id]": payload.currency_id
          ? payload.currency_id
          : undefined,
        page: payload.page,
        per_page: payload.per_page,
      },
    });

    return data.data.map((item) => ApplicationHistoryResponseFactory(item));
  }
}
